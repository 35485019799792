import { render, staticRenderFns } from "./cardV1.vue?vue&type=template&id=698c78c8&scoped=true"
import script from "./cardV1.vue?vue&type=script&lang=js"
export * from "./cardV1.vue?vue&type=script&lang=js"
import style0 from "./cardV1.vue?vue&type=style&index=0&id=698c78c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "698c78c8",
  null
  
)

export default component.exports